import React from "react"
import { Link } from "gatsby"
import styles from "./header.module.css"
import logo from "../../images/logo.svg"

console.warn(logo);

const Header = () => (
  <header className={styles.header}>
    <div className={styles[`header__wrap`]}>
      <h1 className={styles[`header__heading`]}>
        <Link
          to="/"
          className={`${styles[`header__link`]} ${
            styles[`header__link--home`]
            }`}
        >
          <img src={logo} alt="Weave Sales Tools" />
        </Link>
      </h1>
      <nav role="main" className={styles[`header__nav`]}>
        {/* <Link to="/" className={styles[`header__link`]}>
          Home
        </Link> */}
        <Link to="/app/roi" className={styles[`header__link`]}>
          ROI Calculator
        </Link>
        <Link to="/app/efficiency" className={styles[`header__link`]}>
          Efficiency Calculator
        </Link>
      </nav>
    </div>
  </header>
)

export default Header
